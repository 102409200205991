import { Card, CardHeader, List } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import { useCockpitInfo } from "global/hook/cockpit/use-cockpit-info";
import {
  HREF_RECHNUNGSDATENATH_REGION_ZUORDNEN,
  HREF_RECHNUNGSDATEN_GLNS_OHNE_ZUORDNUNG,
  HREF_RECHNUNGSDATEN_UNPLAUSIBLE_RECHNUNGEN,
} from "global/util/routes";
import { ColoredListItemTypography } from "page/cockpit/components/ListItemTypography/ListItemTypography";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CockpitListItemButton } from "../ListItemButton/ListItemButton";
import { CockpitListItemText } from "../ListItemText/ListItemText";

const Rechnungen: React.FC = () => {
  // Variablen und Hooks
  const cockpitInfo = useCockpitInfo()[0];
  const [billsWithoutAssignment, setCountOfNotes] = useState(Number);
  const [billsWithoutAssignmentVisible, setBillsWithoutAssignmentVisible] =
    useState(Boolean);
  const [unplausibleRechnungenCount, setUnplausibleRechnungenCount] =
    useState(0);

  const accessContext = useContext(AccessContext);
  const access = accessContext.getAccessContainer();

  let navigate = useNavigate();
  useEffect(() => {
    setCountOfNotes(
      cockpitInfo.cockpitInformationDTO?.sapRdNichtZugeordnetGh ?? 0
    );
    setBillsWithoutAssignmentVisible(
      cockpitInfo.visibilities?.sapRdNichtZugeordnetGhShow !== undefined
        ? cockpitInfo.visibilities?.sapRdNichtZugeordnetGhShow
        : false
    );
    setUnplausibleRechnungenCount(
      cockpitInfo.cockpitInformationDTO?.sapUnplausibleRechnungen ?? 0
    );
  }, [cockpitInfo]);

  // React-Tree
  return (
    <Card hidden={!billsWithoutAssignmentVisible}>
      <CardHeader title="Rechnungen ATH" />
      <List disablePadding>
        <CockpitListItemButton
          divider
          aria-label="Rechnungen"
          onClick={() =>
            navigate(
              access.showEntryGlnOhneZuordnung
                ? HREF_RECHNUNGSDATEN_GLNS_OHNE_ZUORDNUNG
                : HREF_RECHNUNGSDATENATH_REGION_ZUORDNEN
            )
          }
        >
          <CockpitListItemText primary="GLN's ohne Zuordnung: " />
          <ColoredListItemTypography
            colorChangeBool={billsWithoutAssignment <= 0}
            text={`${billsWithoutAssignment}`}
            id="billsWithoutAssignment"
          />
        </CockpitListItemButton>
        <CockpitListItemButton
          divider
          aria-label="Rechnungen"
          onClick={() => navigate(HREF_RECHNUNGSDATEN_UNPLAUSIBLE_RECHNUNGEN)}
        >
          <CockpitListItemText primary="Unplausible Rechnungen: " />
          <ColoredListItemTypography
            colorChangeBool={unplausibleRechnungenCount <= 0}
            text={`${unplausibleRechnungenCount}`}
            id="unplausibleRechnungenCount"
          />
        </CockpitListItemButton>
      </List>
    </Card>
  );
};

export default Rechnungen;

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Checkbox,
  Chip,
  Stack,
  StackProps,
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import "global/components/ui.scss";
import { Optional } from "global/util/interface/MappedTypesTransform";
import React, { useCallback, useEffect, useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface props {
  /**
   * Name to display in front of the Textfield
   */
  label: string;
  /**
   * Selectable Items in Dropdown
   */
  options: Array<EnumeratorItem>;
  /**
   * Unique Identifier
   */
  id?: string;
  /**
   * Enables the cross on the right side to clear the TextField
   */
  enableClearable?: boolean;
  /**
   * Maximum of selected Items to display in TextField
   */
  limitTags?: number;
  /**
   * Toggle this prop to reset back to the default values
   */
  resetSelectedValues?: boolean;
  /**
   * An Array of EnumeratorItem.id's
   */
  defaultValues?: Array<EnumeratorItem["id"]>;
  /**
   * Insert callback to get selected Items
   */
  onChange: (values: Array<EnumeratorItem>) => void;
  /**
   * Insert function to call, when Enter key is pressed
   */
  onEnterKey?: () => void;
  AutocompleteProps?: Optional<
    AutocompleteProps<EnumeratorItem, true, boolean, undefined>
  >;
  TypographyProps?: TypographyProps;
  StackProps?: StackProps;
}

// self derived from https://mui.com/material-ui/react-select/
// check for more information

const MultipleSelectCheckbox: React.FC<props> = (props: props) => {
  const [value, setValue] = useState<Array<EnumeratorItem> | undefined>([]);

  const findDefaultValues = useCallback(() => {
    return props.options.filter((enumeratorItem) => {
      if (props.defaultValues) {
        return props.defaultValues.includes(enumeratorItem.id);
      } else return false;
    });
  }, [props.options, props.defaultValues]);

  // Reset to defaultValues
  useEffect(() => {
    setValue(props.defaultValues ? findDefaultValues() : []);
  }, [props.resetSelectedValues, props.defaultValues, findDefaultValues]);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="flex"
      {...props.StackProps}
    >
      {props.label && (
        <Typography
          variant="textFieldLabel"
          width="6rem"
          {...props.TypographyProps}
        >
          {props.label}
        </Typography>
      )}
      <Box width="80%">
        <Autocomplete
          size="small"
          multiple
          fullWidth
          limitTags={props.limitTags}
          value={value}
          options={props.options}
          disableCloseOnSelect
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.value}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.value}
            </li>
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option.id}
                label={option.value}
                size="small"
              />
            ));
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (event) => {
                    if (event.key === "Enter") {
                      event.stopPropagation();
                    }
                  },
                }}
              />
            );
          }}
          onChange={(event, selectedValues) => {
            setValue(selectedValues);
            props.onChange(selectedValues);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter" && props.onEnterKey) {
              props.onEnterKey();
            }
          }}
          {...props.AutocompleteProps}
        />
      </Box>
    </Stack>
  );
};

export default MultipleSelectCheckbox;

import {
  SnackbarGenerator,
  SnackbarGeneratorFunction,
} from "App/hook/use-snackbars";
import {
  cutOffBase64DefinitionFromString,
  fileToBase64,
} from "global/util/files/fileToBase64";
import { Optional } from "global/util/interface/MappedTypesTransform";
import NoteRequestDTO from "service/data-service/notizen-controller/interface/NoteRequest";
import { UNPLAUSIBLE_RECHNUNG_NOTE_TYPE } from "../NotizenPanel";

const NotizenController = {
  getFileNameAndContent: function (
    file: File | null,
    getValue: (value: Optional<NoteRequestDTO>) => void,
    showError?: SnackbarGeneratorFunction
  ): void {
    if (!file) {
      getValue({
        filename: "",
        fileContent: "",
      });
      return;
    }

    fileToBase64(file, (error, result) => {
      if (error !== null) {
        if (showError) showError("Beim Dateiabruf ist ein Fehler aufgetreten!");
      } else if (result !== null) {
        getValue({
          filename: file.name,
          fileContent: cutOffBase64DefinitionFromString(result?.toString()),
        });
      }
    });
  },
  /**
   * Checks if all attributes of the NoteRequestDTO are valid for the NoteService.noteSaveOrUpdate API call
   */
  isValidNewNoteInput: function (
    noteRequest: NoteRequestDTO,
    snackbarGenerator: SnackbarGenerator
  ) {
    if (noteRequest.type === "") {
      snackbarGenerator.showWarn("Bitte einen Notiz Typ auswählen", {
        persist: false,
      });
      return false;
    } else if (noteRequest.status === "") {
      snackbarGenerator.showWarn("Bitte einen Notiz Status auswählen", {
        persist: false,
      });
      return false;
    } else if (noteRequest.notizKopfText === "") {
      snackbarGenerator.showWarn("Bitte einen Text für die Notiz eingeben", {
        persist: false,
      });
      return false;
    } else if (
      noteRequest.type === UNPLAUSIBLE_RECHNUNG_NOTE_TYPE &&
      noteRequest.vorgangNr === ""
    ) {
      snackbarGenerator.showWarn(
        "Bitte eine Vorgangs-Nummer für die Unplausible Rechnung eingeben",
        {
          persist: false,
        }
      );
      return false;
    }
    return true;
  },
};

export default NotizenController;

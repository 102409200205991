import { Button, Grid, Stack } from "@mui/material";
import Enumerator, {
  EnumeratorID,
  EnumeratorItem,
} from "global/components/EnumeratedValues/Enumerator";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import { useAutomatData } from "global/hook/datastore/use-automat-datastore";
import React, { useEffect, useState } from "react";
import { AutomatUnplausibleRechnungItem } from "service/sap-service/interface/UnplausibleRechnungRecord";

export interface AutomatDeploymentInfoDisplayData {
  umstellungType: EnumeratorID;
  geplanteDate?: Date;
  seit?: Date;
  value: EnumeratorID;
}

interface UnplausibleRechnungNotePopperContentProps {
  automatKey: string;
  hideAction: Function;
  setAutomatUnplausibleRechnungItem: (
    item: AutomatUnplausibleRechnungItem
  ) => void;
}

const UnplausibleRechnungNotePopperContent: React.FC<
  UnplausibleRechnungNotePopperContentProps
> = (props: UnplausibleRechnungNotePopperContentProps) => {
  const [automatData, dispatchAutomatData, dataWithChanges] = useAutomatData(
    props.automatKey
  );

  const [unplausibleRechnungItems, setUnplausibleRechnungItems] =
    useState<Enumerator>({ items: [] as Array<EnumeratorItem> } as Enumerator);

  const [noteItem, setNoteItem] = useState<EnumeratorItem | null>(null);

  const handleOkClick = () => {
    props.setAutomatUnplausibleRechnungItem(
      noteItem?.source as AutomatUnplausibleRechnungItem
    );
    props.hideAction();
  };

  useEffect(() => {
    var unplausibleRechnungItems = automatData.unplausibleRechnungItems ?? [];
    if (!automatData.unplausibleRechnungItems) {
      dispatchAutomatData("FETCH_UNPLAUSIBLE_RECHNUNG_ITEMS");
    }
    const enumItems = unplausibleRechnungItems.map((item) => {
      return {
        id: item.vorgang_nr,
        value: `${item.vorgang_nr} - [ ${item.regulierungs_beleg} ]`,
        source: item,
      } as EnumeratorItem;
    });
    setUnplausibleRechnungItems({ items: enumItems } as Enumerator);
  }, [automatData]);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectBox
            id="vorgangs-nummer-select-box"
            label="Vorgang Nummer:"
            enumerator={unplausibleRechnungItems}
            getSelectedValue={(item) => setNoteItem(item)}
            TypographyProps={{ variant: "h2" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack justifyContent={"right"} direction={"row"} spacing={2}>
            <Button
              variant="contained"
              onClick={handleOkClick}
              disabled={noteItem === null}
            >
              OK
            </Button>
            <Button
              onClick={() => props.hideAction()}
              sx={{
                display: "inline",
              }}
            >
              Abbrechen
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default UnplausibleRechnungNotePopperContent;

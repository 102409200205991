import { Stack, Typography, TypographyProps } from "@mui/material";
import { PropsWithChildren } from "react";

import "./fieldelementinfo.scss";

interface FieldElementInfoProps {
  label: string;
  value?: string;
  labelTypographyProps?: TypographyProps;
  valueTypographyProps?: TypographyProps;
}

const FieldElementInfo = <T,>(
  props: PropsWithChildren<FieldElementInfoProps>
) => {
  const labelTypographyProps = {
    ...(props.labelTypographyProps ?? {}),
    className: `${
      props.labelTypographyProps?.className ?? ""
    } fieldElementInfo__rowHeader`,
  };

  const valueTypographyProps = {
    ...(props.valueTypographyProps ?? {}),
    className: `${
      props.valueTypographyProps?.className ?? ""
    } fieldElementInfo__rowContent`,
  };

  return (
    <Stack direction="row" spacing={1}>
      <Typography
        id={`fieldElementInfo-label-${props.label}`}
        {...labelTypographyProps}
      >
        {props.label}
      </Typography>
      <Typography
        id={`fieldElementInfo-value-${props.label}`}
        {...valueTypographyProps}
      >
        {props.value ?? ""}
      </Typography>
    </Stack>
  );
};

export default FieldElementInfo;

import { TypographyProps } from "@mui/material";
import Enumerator, {
  enumeratorItemForId,
} from "global/components/EnumeratedValues/Enumerator";
import AutomatDataRecord from "global/hook/datastore/interface/AutomatDataRecord";
import { DispatchAutomatDataFunction } from "global/hook/datastore/use-automat-datastore";
import { PropsWithChildren, useEffect, useState } from "react";
import AutomatenblattInformation, {
  DeploymentInfo,
  DeploymentInfoType,
} from "service/data-service/automate-controller/interface/AutomatenblattInformation";

import FieldElementInfo from "global/components/UI/FieldElementInfoBundle/FieldElementInfo";
import FieldElementInfoBundle from "global/components/UI/FieldElementInfoBundle/FieldElementInfoBundle";
import {
  AUTOMAT_GEPLANTE_UMSTELLUNG_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import DateUtils from "global/util/DateUtils";
import { AutomatDeploymentInfoControllerContext } from "./AutomatDeploymentInfoController";
import AutomatDeploymentInfoPopperContent from "./AutomatDeploymentInfoPopperContent";
import "./automatgeplanteumstellungbox.scss";

interface AutomatDeploymentInfoProps {
  label: string;
  type: DeploymentInfoType;
  referenceData: AutomatDataRecord;
  automatenblattInformation: AutomatenblattInformation;
  dispatchAutomatDataChange: DispatchAutomatDataFunction;
  auspragungEnumerator: Enumerator;
  TypographyProps?: TypographyProps;
}

interface DeploymentInfoDisplayData {
  value?: string;
  seit?: string;
  geplanteUmstellung?: string;
}

const extractDeploymentInfo = (
  info: AutomatenblattInformation,
  type: DeploymentInfoType
): DeploymentInfo => {
  const deploymentInfo = info.deploymentInfo.find((info) => info.type === type);
  return (
    deploymentInfo ?? {
      type,
      value: "",
      seit: undefined,
      geplanteDate: undefined,
      umstellungType: "OPEN",
    }
  );
};

const AutomatDeploymentInfo = <T,>(
  props: PropsWithChildren<AutomatDeploymentInfoProps>
) => {
  const automatDeploymentStatus: Enumerator = useEnumerator(
    AUTOMAT_GEPLANTE_UMSTELLUNG_ENUM_ID
  );

  const [displayData, setDisplayData] = useState<DeploymentInfoDisplayData>({});

  const extractControllerContext = () => {
    return {
      deploymentInfoType: props.type,
      dispatchUpdate: props.dispatchAutomatDataChange,
      auspragungEnumerator: props.auspragungEnumerator,
      referenceData: extractDeploymentInfo(
        props.referenceData.automatenblattInformation,
        props.type
      ),
      userData: extractDeploymentInfo(
        props.automatenblattInformation,
        props.type
      ),
    };
  };

  const [controllerContext, setControllerContext] =
    useState<AutomatDeploymentInfoControllerContext>(
      extractControllerContext()
    );

  const umstellunDisplayString = () => {
    const data = controllerContext.userData;
    const displayData =
      automatDeploymentStatus.items.find(
        (item) => item.id === (data.umstellungType ?? "OPEN")
      )?.value ?? "";

    if (data.umstellungType === "PLANNED_FOR") {
      return `${displayData} - ${DateUtils.convertApiDateToDisplayDate(
        data.geplanteDate
      )}`;
    }
    return displayData;
  };

  useEffect(() => {
    setControllerContext(extractControllerContext());
  }, [
    props.automatenblattInformation,
    props.referenceData,
    props.type,
    props.auspragungEnumerator,
  ]);

  useEffect(() => {
    const data = controllerContext.userData;
    setDisplayData({
      value: enumeratorItemForId(props.auspragungEnumerator, data.value ?? "")
        ?.value,
      seit: DateUtils.convertApiDateToDisplayDate(data.seit),
      geplanteUmstellung: umstellunDisplayString(),
    });
  }, [controllerContext.userData, props.auspragungEnumerator]);

  return (
    <>
      <FieldElementInfoBundle
        label={props.label}
        popperContent={(hideAction) => (
          <AutomatDeploymentInfoPopperContent
            id={`${props.type}-deployment-info-popper-content`}
            umstellungOptions={automatDeploymentStatus}
            context={controllerContext}
          />
        )}
        popperClassName="deploymentinfo__Popover__popper"
      >
        <FieldElementInfo label="Ausprägung:" value={displayData.value} />
        <FieldElementInfo label="seit:" value={displayData.seit} />
        <FieldElementInfo
          label="geplante Umstellung:"
          value={displayData.geplanteUmstellung}
          valueTypographyProps={{
            className: "epcom__deploymentInfoContent__umstellungGeplant",
          }}
        />
      </FieldElementInfoBundle>
    </>
  );
};

export default AutomatDeploymentInfo;

export const HREF_HOME = "/";
export const HREF_AUTOMATENSUCHE = "/automatensuche";
export const HREF_AUTOMATENKAUFANFRAGEN = "/automatenkaufanfragen";
export const HREF_BERICHTE_AUTOMATENSTATUS = "/berichteautomatenstatus";
export const HREF_BERICHTE_MONITORING = "/berichtemonitoring";
export const HREF_BERICHTE_TAGESSTATUS = "/berichtetagesstatus";
export const HREF_BERICHTE_ANSICHTFEHLER = "/berichteansichtfehler";
export const HREF_BERICHTE_ANSICHTWECHSEL = "/berichteansichtwechsel";
export const HREF_BERICHTE_ERREICHBARKEITEN = "/berichteerreichtbarkeiten";
export const HREF_BERICHTE_ZURBEARBEITUNG = "/berichtezurarbeitung";
export const HREF_BERICHTE_FLATFILE = "/berichteflatfile";
export const HREF_BERICHTE_STATUSDATEN = "/berichtestatusdaten";
export const HREF_BERICHTE_DSMD_FEHLERHAFT = "/berichtedsmdfehlerhaft";
export const HREF_BERICHTE_VERFUGBARKEISANALYSE =
  "/berichteverfugbarkeisanalyse";
export const HREF_BERICHTE_AUTOMATDATEN_GT_30 = "/berichteautomatdatengt30";
export const HREF_DATENPFLEGE_RNS = "/datenpflegerns";
export const HREF_NOTIZEN_SUCHE = "/notizensuche";
export const HREF_NOTIZEN_WIEDERVORLAGE = "/notizenwiedervorlage";
export const HREF_HILFE_BENUTZERHANDBUCH = "/hilfebenutzerhandbuch";
export const HREF_ADMIN_ANWENDERNACHRICHTEN = "/adminanwendernachrichten";
export const HREF_ADMIN_ANLAGENEUEUSER = "/adminanlageneueuser";
export const HREF_ADMIN_ANLAGERECHNUNGSNEMER = "/adminanlagerechnungsnemer";
export const HREF_ADMIN_SAPSTAMMDATENMIGRATION = "/adminsapstammdatenmigration";
export const HREF_ADMIN_STAMMDATENABHOLUNG = "/adminstammdatenabholung";
export const HREF_ADMIN_IPBEREICH = "/adminipbereich";
export const HREF_ADMIN_IPWHITELIST = "/adminipwhitelist";
export const HREF_ADMIN_JOB_MONITORING = "/jobmonitoring";
export const HREF_ADMIN_MAINTENANCE = "/adminmaintenance";
export const HREF_RECHNUNGSDATEN_SUCHE = "/rechnungsdatensuche";
export const HREF_RECHNUNGSDATEN_GLNS_OHNE_ZUORDNUNG =
  "/rechnungsdatenglnohnezuordnung";
export const HREF_RECHNUNGSDATENATH_WGR_ZUORDNUNG =
  "/rechnungsdatenathwgrzuordnung";
export const HREF_RECHNUNGSDATENATH_POSITIONEN = "/rechnungsdatenathpositionen";
export const HREF_RECHNUNGSDATENATH_REGION_ZUORDNEN =
  "/rechnungsdatenathregionzuordnen";
export const HREF_RECHNUNGSDATEN_UNPLAUSIBLE_RECHNUNGEN =
  "/rechnungsdatenunplausiblerechnungen";

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import Enumerator, {
  EnumeratorID,
  enumeratorItemForId,
} from "global/components/EnumeratedValues/Enumerator";
import React, { useEffect, useState } from "react";

import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import DateUtils from "global/util/DateUtils";
import AutomatDeploymentInfoController, {
  AutomatDeploymentInfoControllerContext,
} from "./AutomatDeploymentInfoController";
import "./automatgeplanteumstellungbox.scss";

export interface AutomatDeploymentInfoDisplayData {
  umstellungType: EnumeratorID;
  geplanteDate?: Date;
  seit?: Date;
  value: EnumeratorID;
}

interface AutomatDeploymentInfoPopperContentProps {
  id: string;
  umstellungOptions: Enumerator;
  context: AutomatDeploymentInfoControllerContext;
}

export const ITEM_WITH_DATE: EnumeratorID = "PLANNED_FOR";

const AutomatDeploymentInfoPopperContent: React.FC<
  AutomatDeploymentInfoPopperContentProps
> = (props: AutomatDeploymentInfoPopperContentProps) => {
  const [datePickerClassName, setDatePickerClassName] = useState<string>("");

  const [displayData, setDisplayData] =
    useState<AutomatDeploymentInfoDisplayData>({
      umstellungType: props.context.userData.umstellungType ?? "OPEN",
      geplanteDate:
        DateUtils.parseIsoDateString(props.context.userData.geplanteDate) ??
        undefined,
      seit:
        DateUtils.parseIsoDateString(props.context.userData.seit) ?? undefined,
      value: props.context.userData.value ?? "",
    });

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data: AutomatDeploymentInfoDisplayData = {
      ...displayData,
      umstellungType: event.target.value,
    };
    setDisplayData(data);
    AutomatDeploymentInfoController.geplanteUmstellungChangeHandler(
      props.context,
      data.umstellungType,
      data.geplanteDate
    );
  };
  const handleDateChange = (date: Date | null) => {
    const data: AutomatDeploymentInfoDisplayData = {
      ...displayData,
      geplanteDate: date ?? undefined,
    };
    setDisplayData(data);
    AutomatDeploymentInfoController.geplanteUmstellungChangeHandler(
      props.context,
      data.umstellungType,
      data.geplanteDate
    );
  };

  useEffect(() => {
    setDatePickerClassName(
      displayData.umstellungType === ITEM_WITH_DATE
        ? "epcom__SelectWithPopover__datepicker"
        : "epcom__SelectWithPopover__disabled__datepicker"
    );
  }, [displayData]);

  useEffect(() => {
    setDisplayData({
      umstellungType: props.context.userData.umstellungType ?? "OPEN",
      geplanteDate:
        DateUtils.parseIsoDateString(props.context.userData.geplanteDate) ??
        undefined,
      seit:
        DateUtils.parseIsoDateString(props.context.userData.seit) ?? undefined,
      value: props.context.userData.value ?? "",
    });
  }, [props.context.userData]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
      <Stack>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectBox
              id="auspragung-select-box"
              label="Ausprägung:"
              enumerator={props.context.auspragungEnumerator}
              selection={enumeratorItemForId(
                props.context.auspragungEnumerator,
                displayData.value
              )}
              getSelectedValue={AutomatDeploymentInfoController.valueChangeHandler.bind(
                null,
                props.context
              )}
              TypographyProps={{ variant: "h2" }}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePickerElement
              label="Seit:"
              id="seit-datepicker"
              defaultValue={displayData.seit}
              getSelectedValue={AutomatDeploymentInfoController.seitChangeHandler.bind(
                null,
                props.context
              )}
              datePickerProperties={{
                disableFuture: true,
              }}
              TypographyProps={{ variant: "h2" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2" id={`${props.id}-label`}>
              geplante Umstellung
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <RadioGroup
              value={displayData.umstellungType}
              onChange={handleOptionChange}
            >
              {props.umstellungOptions.items.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.id}
                  control={<Radio />}
                  label={item.value}
                  slotProps={{ typography: { variant: "caption" } }}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={deLocale}
            >
              <StaticDatePicker
                className={datePickerClassName}
                value={displayData.geplanteDate ?? new Date()}
                onChange={handleDateChange}
                displayStaticWrapperAs="desktop"
                disablePast
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Stack>
    </LocalizationProvider>
  );
};

export default AutomatDeploymentInfoPopperContent;

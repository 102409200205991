import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Typography,
} from "@mui/material";
import { enumeratorItemForValue } from "global/components/EnumeratedValues/Enumerator";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import AutomatChangeInfo, {
  ChangeDataType,
} from "global/hook/datastore/interface/AutomatChangeInfo";
import AutomatDataRecord from "global/hook/datastore/interface/AutomatDataRecord";
import { DispatchAutomatDataFunction } from "global/hook/datastore/use-automat-datastore";
import {
  AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useEffect, useState } from "react";
import { KommTypeVisibility } from "service/data-service/access-controller/interface/Access";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import { PanelBaseProps } from "../../AutomatDetailPanelContainer/AutomatDetailPanelContainer";
import TkPartnerController, {
  TKDetailsInformation,
} from "./script/TKPartnerController";

import "./tkpartnerdetailcard.scss";

export const MENGENMELDUNG_TYP_ID = "AT_MM";
export const ARTIKELSTAMM_TYPE_ID = "CD_STD";
export const QUITERUNG_TYPE_ID = "CD_MMQUIT";
export const DPGBTB_TYPE_ID = "AT_DPGBTB";
export const EDKBTB_TYPE_ID = "AT_EDKBTB";
export const LOGGING_TYPE_ID = "AT_LOG";

export interface TKPartnerDetailCardVisibility extends KommTypeVisibility {
  readonly changesAllowed?: boolean;
}

interface TKPartnerDetailCardProps extends PanelBaseProps {
  label: string;
  referenceData: AutomatDataRecord;
  automatenblattInformation: AutomatenblattInformation;
  typ_id: string;
  visibilitySettings: TKPartnerDetailCardVisibility;
  onOneEmptyField?: (markWithColor: boolean) => void;
  dispatchAutomatDataChange: DispatchAutomatDataFunction;
}

/**
 * Contains all Information shown on TKPartnerDetailCard
 */

const TKPartnerDetailCard = (props: TKPartnerDetailCardProps) => {
  const [tkDetailsInformation, setTkDetailsInformation] =
    useState<TKDetailsInformation>(
      TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
        props.automatenblattInformation,
        props.typ_id
      )
    );
  const [refTkDetailsInformation, setRefTkDetailsInformation] =
    useState<TKDetailsInformation>(
      TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
        props.referenceData.automatenblattInformation,
        props.typ_id
      )
    );
  const [markedRed, setMarkedRed] = useState(false);

  const datenspediteurEnumerator = useEnumerator(
    AUTOMATENBLATT_DATENSPEDITEUR_ENUM_ID
  );

  function hasDeleteChange() {
    return props.referenceData.changes
      ?.filter((e) => e.path.indexOf(props.typ_id) !== -1)
      .find((e) => e.path.indexOf("delete") !== -1);
  }

  const activeStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const currentValue =
      refTkDetailsInformation.credentialsInfo?.activated ??
      event.target.checked;
    registerCredentialChange("activated", currentValue, event.target.checked);
  };

  const registerCredentialChange = (
    credentialAttribute: string,
    referenceValue: ChangeDataType,
    newValue: ChangeDataType
  ) => {
    TkPartnerController.registerAutomatCredentialChange(
      props.typ_id,
      credentialAttribute,
      {
        refValue: referenceValue,
        value: hasDeleteChange() ? referenceValue : newValue,
      } as AutomatChangeInfo,
      props.dispatchAutomatDataChange
    );
  };

  const registerTkDetailChange = (
    detailAttribute: string,
    referenceValue: string,
    newValue: string
  ) => {
    TkPartnerController.registerTKDetailsChange(
      props.typ_id,
      detailAttribute,
      {
        refValue: referenceValue,
        value: hasDeleteChange() ? referenceValue : newValue,
      } as AutomatChangeInfo,
      props.dispatchAutomatDataChange
    );
  };

  const onOneEmptyField = props.onOneEmptyField;
  useEffect(() => {
    TkPartnerController.markHeaderIfOneEntryIsEmpty(
      refTkDetailsInformation,
      () => {
        if (onOneEmptyField) onOneEmptyField(true);
      },
      () => {
        if (onOneEmptyField) onOneEmptyField(false);
      }
    ); // eslint-disable-next-line
  }, [refTkDetailsInformation]);
  useEffect(() => {
    TkPartnerController.markHeaderIfOneEntryIsEmpty(
      tkDetailsInformation,
      () => {
        setMarkedRed(true);
      },
      () => {
        setMarkedRed(false);
      }
    ); // eslint-disable-next-line
  }, [tkDetailsInformation]);

  // Reset on Verwerfen
  useEffect(() => {
    setRefTkDetailsInformation(
      TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
        props.referenceData.automatenblattInformation,
        props.typ_id
      )
    );
    setTkDetailsInformation(
      TkPartnerController.mapAutomatenblattInformationToTkDetailsInformation(
        props.automatenblattInformation,
        props.typ_id
      )
    ); // eslint-disable-next-line
  }, [props.referenceData]);

  return (
    <Accordion>
      <Box className="tk-partner-card-summary">
        <AccordionSummary
          id={`${props.label}-panel-header`}
          expandIcon={<ExpandMore />}
        >
          <Typography
            variant={"accordionSummary"}
            color={markedRed ? "error" : "#222"}
          >
            {markedRed ? `*${props.label}*` : props.label}
          </Typography>
        </AccordionSummary>
        <Switch
          className="tk-partner-card-switch"
          checked={tkDetailsInformation.credentialsInfo?.activated === true}
          size="small"
          color={markedRed ? "error" : "primary"}
          onChange={activeStatusChange}
        />
      </Box>
      <AccordionDetails>
        <SelectBox
          label="Datenspediteur:"
          enumerator={datenspediteurEnumerator}
          selection={enumeratorItemForValue(
            datenspediteurEnumerator,
            tkDetailsInformation.detail?.kommPartnerName ?? ""
          )}
          id={`${props.label}-ds`}
          getSelectedValue={(value) => {
            registerTkDetailChange(
              "kommPartnerName",
              refTkDetailsInformation.detail?.kommPartnerName ?? "",
              value?.id.toString() ?? ""
            );
          }}
          reset={props.resetValues}
          AutocompleteProps={{
            readOnly: !props.visibilitySettings.changesAllowed,
          }}
        />
        {tkDetailsInformation.isArtikelStammdatenDetail && (
          <TextValue
            label="Variante Artikelstamm:"
            value={tkDetailsInformation.detail?.artikelstammVariante ?? ""}
            id="artikelstamm-variante"
            onChange={(event) => {
              registerTkDetailChange(
                "artikelstammVariante",
                refTkDetailsInformation.detail?.artikelstammVariante ?? "",
                event.target.value
              );
            }}
            TextFieldProps={{
              inputProps: {
                readOnly: !props.visibilitySettings.changesAllowed,
              },
            }}
          />
        )}
        <TextValue
          label={
            tkDetailsInformation.isArtikelStammdatenDetail
              ? "Verzeichnis EAN Stammdaten:"
              : "Verzeichnis Rohdaten:"
          }
          value={tkDetailsInformation.detail?.ftpDirectory ?? ""}
          id={`${props.label}-path`}
          onChange={(event) => {
            registerTkDetailChange(
              "ftpDirectory",
              refTkDetailsInformation.detail?.ftpDirectory ?? "",
              event.target.value
            );
          }}
          TextFieldProps={{
            inputProps: {
              readOnly: !props.visibilitySettings.changesAllowed,
            },
          }}
        />
        <TextValue
          label="Automat User:"
          value={tkDetailsInformation.credentialsInfo?.username ?? ""}
          id={`${props.label}-user`}
          type={props.visibilitySettings.hideAutomatUser ? "password" : "text"}
          onChange={(event) => {
            registerCredentialChange(
              "username",
              refTkDetailsInformation.credentialsInfo?.username ?? "",
              event.target.value
            );
          }}
          TextFieldProps={{
            inputProps: {
              readOnly:
                !props.visibilitySettings.changesAllowed ||
                props.visibilitySettings.hideAutomatUser,
            },
          }}
        />
        <TextValue
          label="Automat Password:"
          value={tkDetailsInformation.credentialsInfo?.password ?? ""}
          id={`${props.label}-pwd`}
          type="password"
          onChange={(event) => {
            registerCredentialChange(
              "password",
              refTkDetailsInformation.credentialsInfo?.password ?? "",
              event.target.value
            );
          }}
          TextFieldProps={{
            inputProps: {
              readOnly:
                !props.visibilitySettings.changesAllowed ||
                props.visibilitySettings.hideAutomatPassword,
            },
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TKPartnerDetailCard;

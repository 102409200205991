import { Tab } from "@mui/material";
import AutomatTabLabel from "global/components/UI/AutomatTab/AutomatTabLabel";
import findMachineKeyViaSerialNumber from "global/util/REST/findMachineKey";
import { noop } from "global/util/utils";
import { Automatenblatt } from "page/Automatensuche/components/Automatenblatt/automatenblatt";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NavigateFunction, Route, useNavigate } from "react-router-dom";
import {
  AutomatDataStore,
  AutomatStoreController,
  TABS_PSEUDO_AUTOMAT_KEY,
  useAutomatData,
} from "../datastore/use-automat-datastore";
import ModalController from "../modal/interface/ModalController";
import useModalController from "../modal/use-modals";
import AutomatTabController from "./interface/AutomatTabController";
import AutomatTabInfo from "./interface/AutomatTabInfo";

export type AutomatTabStore = Array<AutomatTabInfo>;

export const AUTOMAT_SEARCH_TAB_KEY = "-";
let globalAutomatTabStore: AutomatTabStore = [];
let listeners: Array<Dispatch<SetStateAction<AutomatTabStore>>> = [];
let needsActiveAutomatKey = "";
let activeAutomatKey = AUTOMAT_SEARCH_TAB_KEY;

const navigateToAutomat = (navigator: NavigateFunction, automatKey: string) => {
  const index = globalAutomatTabStore.findIndex(
    (e) => e.automatKey === automatKey
  );
  if (index !== -1) {
    const serialNumber = `${globalAutomatTabStore[index].serialNumber}`;
    activeAutomatKey = automatKey;
    needsActiveAutomatKey = "";
    navigator(
      `/automatensuche/${serialNumber}${AutomatStoreController.automatRoutePath(
        serialNumber
      )}`
    );
  }
};

const switchToTab = (navigator: NavigateFunction, tabKey: string) => {
  if (tabKey === AUTOMAT_SEARCH_TAB_KEY) {
    activeAutomatKey = tabKey;
    needsActiveAutomatKey = "";
    navigator("/automatensuche");
  } else {
    needsActiveAutomatKey = tabKey;
    navigateToAutomat(navigator, tabKey);
  }
  globalAutomatTabStore = [...globalAutomatTabStore];
  listeners.forEach((l) => l(globalAutomatTabStore));
};

const openTab = (
  navigator: NavigateFunction,
  automatSerienNr: string,
  automatKey?: string,
  panelIndex?: number
) => {
  if (!automatKey) {
    findMachineKeyViaSerialNumber(automatSerienNr, (key) => {
      AutomatStoreController.initialiseAutomat(
        key,
        panelIndex,
        parseInt(automatSerienNr)
      );
      setTimeout(() => switchToTab(navigator, key), 60);
    });
  } else {
    AutomatStoreController.initialiseAutomat(
      automatKey,
      panelIndex,
      parseInt(automatSerienNr)
    );
    setTimeout(() => switchToTab(navigator, automatKey), 60);
  }
};

const closeTab = (
  navigator: NavigateFunction,
  dialogController: ModalController,
  dataStore: AutomatDataStore,
  automatKey: string
) => {
  const automatRecord = dataStore.find((e) => e.automatKey === automatKey);

  if (
    automatRecord &&
    automatRecord.changes &&
    automatRecord.changes.length > 0
  ) {
    dialogController.showMessageDialog(
      "Der Automat, den Sie versuchen zu schließen, hat ungespeicherte Änderungen. Bitte speichern Sie diese, bevor Sie den Automaten schließen.",
      undefined,
      "Ungespeicherte Änderungen vorhanden!",
      noop,
      true
    );
    return;
  }

  const index = globalAutomatTabStore.findIndex(
    (e) => e.automatKey === automatKey
  );
  const nextTabStore = globalAutomatTabStore.filter(
    (e) => e.automatKey !== automatKey
  );

  AutomatStoreController.removeAutomat(automatKey);
  globalAutomatTabStore = [...nextTabStore];

  if (automatKey === activeAutomatKey) {
    let nextAutomatKey = AUTOMAT_SEARCH_TAB_KEY;
    if (nextTabStore.length > 0) {
      const nextIndex =
        index < nextTabStore.length ? index : nextTabStore.length - 1;
      nextAutomatKey = nextTabStore[nextIndex].automatKey;
    }
    setTimeout(() => switchToTab(navigator, nextAutomatKey), 60);
  } else {
    listeners.forEach((l) => l(globalAutomatTabStore));
  }
};

const openAllTabs = (
  navigator: NavigateFunction,
  serienNr: string,
  activeSerienNrs: string,
  panelIndex?: number
) => {
  var aktiveAutomaten = activeSerienNrs.split(";");
  aktiveAutomaten.forEach((value) => {
    if (value !== serienNr) {
      findMachineKeyViaSerialNumber(value, (key) => {
        AutomatStoreController.initialiseAutomat(
          key,
          panelIndex ?? 2,
          parseInt(value)
        );
      });
    }
  });
};

export const useTabs = (): [AutomatTabStore, string, AutomatTabController] => {
  const setState = useState(globalAutomatTabStore)[1];

  const dialogController = useModalController();

  const [, , , automatDatastore] = useAutomatData(TABS_PSEUDO_AUTOMAT_KEY);

  const navigator = useNavigate();

  useEffect(() => {
    navigateToAutomat(navigator, needsActiveAutomatKey);

    const newAutomatTabStore = automatDatastore
      .filter((e) => e.automatKey !== TABS_PSEUDO_AUTOMAT_KEY)
      .map((record) => {
        const automatSerialNumber = `${record.automatenblattInformation.seriennummer}`;

        return {
          automatKey: record.automatKey,
          serialNumber: record.automatenblattInformation.seriennummer,
          tab: (
            <Tab
              id={automatSerialNumber}
              key={automatSerialNumber}
              component={"div"}
              className="tab-element"
              value={record.automatKey}
              label={
                <AutomatTabLabel
                  label={automatSerialNumber}
                  changeCount={(record.changes ?? []).length}
                  switchTab={() => switchToTab(navigator, record.automatKey)}
                  removeTab={() =>
                    closeTab(
                      navigator,
                      dialogController,
                      automatDatastore,
                      record.automatKey
                    )
                  }
                />
              }
            />
          ),
          route: (
            <Route
              key={record.automatenblattInformation.seriennummer}
              path={`${record.automatenblattInformation.seriennummer}/*`}
              element={<Automatenblatt automatKey={record.automatKey} />}
            />
          ),
        };
      });

    globalAutomatTabStore = [...newAutomatTabStore];
    setState(globalAutomatTabStore); // eslint-disable-next-line
  }, [automatDatastore]);

  useEffect(() => {
    listeners.push(setState);

    return () => {
      listeners = listeners.filter((li) => li !== setState);
    };
  }, [setState]);

  return [
    globalAutomatTabStore,
    activeAutomatKey,
    {
      open: openTab.bind(null, navigator),
      close: closeTab.bind(null, navigator, dialogController, automatDatastore),
      openAll: openAllTabs.bind(null, navigator),
      switchTo: switchToTab.bind(null, navigator),
    },
  ];
};

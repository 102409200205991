import { AutomatUnplausibleRechnungItem } from "service/sap-service/interface/UnplausibleRechnungRecord";
import UnplausibleRechnungenService from "service/sap-service/UnplausibleRechnungen.service";
import AutomatAction, {
  AutomatActionCallback,
  AutomatActionPayload,
} from "../interface/AutomatAction";
import AutomatDataRecord from "../interface/AutomatDataRecord";
import { AutomatStoreController } from "../use-automat-datastore";

export const FETCH_UNPLAUSIBLE_RECHNUNG_ITEMS =
  "FETCH_UNPLAUSIBLE_RECHNUNG_ITEMS";

const configureUnplausibleRechnungItemsStore = () => {
  const fetchUnplausibleRechnungItems: AutomatAction = {
    identifier: FETCH_UNPLAUSIBLE_RECHNUNG_ITEMS,
    action: (
      currentState: AutomatDataRecord,
      payload: AutomatActionPayload,
      callback: AutomatActionCallback,
      callbackOnFail: Function
    ) => {
      const items = UnplausibleRechnungenService.findAutomatItems(
        `${currentState.automatenblattInformation.seriennummer}`
      );
      items
        .then((data) =>
          callback({
            unplausibleRechnungItems: data,
          } as AutomatDataRecord)
        )
        .catch((error) => {
          callback({
            unplausibleRechnungItems: [] as AutomatUnplausibleRechnungItem[],
          } as AutomatDataRecord);
          callbackOnFail(error);
        });
    },
  };
  AutomatStoreController.registerAutomatDataStoreActions([
    fetchUnplausibleRechnungItems,
  ]);
};

export default configureUnplausibleRechnungItemsStore;

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { GermanCurrencyValueFormatter } from "page/Automatensuche/components/DataGrid/DataGrid";

const SuchePositionenColumnDefs: Array<ColDef> = [
  {
    headerName: "Liefer/Leistungs-Datum",
    field: "lieferLeistungsDatum",
    minWidth: 140,
    maxWidth: 140,
  },
  {
    headerName: "SerienNr",
    field: "serienNrEpcom",
    minWidth: 90,
    cellClass: (params) => {
      return params.data.knzNotEpcom ? "hinweis" : "actionCell";
    },
  },
  {
    headerName: "RNS-GLN",
    field: "rnsGln",
    minWidth: 120,
  },
  {
    headerName: "RN-GLN",
    field: "rnGln",
    minWidth: 120,
  },
  {
    headerName: "Kurztext",
    field: "kurzText",
    minWidth: 260,
  },
  {
    headerName: "Warengruppe",
    field: "warengruppeName",
    minWidth: 130,
  },
  {
    headerName: "Unterwarengruppe",
    field: "unterWarengruppeName",
    minWidth: 160,
  },
  {
    headerName: "Beleg-Datum",
    field: "belegDatum",
    minWidth: 90,
  },
  {
    headerName: "Fakturierte Menge",
    field: "fakturierteMenge",
    minWidth: 110,
    valueFormatter: GermanCurrencyValueFormatter,
  },
  {
    headerName: "Netto-Stückpreis",
    field: "nettoWertEinzelPreis",
    minWidth: 130,
    valueFormatter: GermanCurrencyValueFormatter,
  },
  {
    headerName: "Storniert",
    field: "storniert",
    minWidth: 80,
  },
  {
    headerName: "Lieferanten-Artikel-Nr.",
    field: "lieferantenArtikelNr",
    minWidth: 160,
  },
  {
    headerName: "Referenz",
    field: "referenz",
    minWidth: 90,
  },
  {
    headerName: "Position Lieferant",
    field: "positionLieferant",
    minWidth: 130,
  },
  {
    headerName: "Regulierungs-Beleg",
    field: "regulierungsBeleg",
    minWidth: 140,
  },
  {
    headerName: "Anlagen",
    field: "anlagen",
    minWidth: 110,
    maxWidth: 110,
    cellStyle: {
      "justify-content": "center",
    },
    cellClass: (params) => {
      return params.data.anlagen &&
        params.data.anlagen !== "" &&
        params.data.anlagen !== "ANLAGEN_NOTFOUND"
        ? "actionCell"
        : "";
    },
    cellRenderer: (params: ICellRendererParams) => {
      return (
        <>
          {params.data.anlagen &&
            params.data.anlagen !== "" &&
            params.data.anlagen !== "ANLAGEN_NOTFOUND" && <SaveAltIcon />}
        </>
      );
    },
  },
  {
    headerName: "Region",
    field: "region",
    minWidth: 70,
    maxWidth: 70,
  },
];

export default SuchePositionenColumnDefs;

import { ColDef } from "ag-grid-community";
import {
  CellValueRenderer,
  GermanCurrencyValueFormatter,
} from "page/Automatensuche/components/DataGrid/DataGrid";

const UnplausibleRechnungenColumnDefs: Array<ColDef> = [
  {
    headerName: "RNS GLN",
    field: "rnsGln",
    minWidth: 120,
  },

  {
    headerName: "Vorgangs-Nr.",
    field: "eventId",
    minWidth: 100,
  },
  {
    headerName: "Vorgang Name",
    field: "eventName",
    minWidth: 200,
  },
  {
    headerName: " Liefer -/ Leistungsdatum",
    field: "leistungsdatum",
    minWidth: 140,
    maxWidth: 140,
    // valueFormatter: (params) =>
    //   DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "SerienNr.",
    field: "seriennr",
    minWidth: 100,
  },
  {
    headerName: "Kurztext",
    field: "kurzText",
    minWidth: 200,
  },
  {
    headerName: "Warengruppe",
    field: "warrengruppe",
    minWidth: 120,
  },
  {
    headerName: "Unterwarengruppe",
    field: "unterwarengruppe",
    minWidth: 160,
  },

  {
    headerName: "Belegdatum",
    field: "belegdatum",
    minWidth: 140,
    maxWidth: 140,
    // valueFormatter: (params) =>
    //   DateUtils.optionalTimeArrayToGermanString(params.value),
  },
  {
    headerName: "Fakturierte Menge",
    field: "fakturierteMenge",
    minWidth: 100,
    valueFormatter: GermanCurrencyValueFormatter,
  },
  {
    headerName: "Netto Stückpreis",
    field: "nettoStuckpreis",
    minWidth: 100,
    valueFormatter: GermanCurrencyValueFormatter,
  },
  {
    headerName: "Lieferanten ArtikelNummer",
    field: "lieferantenArtikelNr",
    minWidth: 160,
  },

  {
    headerName: "Referenz",
    field: "referenz",
    minWidth: 160,
  },

  {
    headerName: "Position Lieferant",
    field: "positionLieferant",
    minWidth: 100,
  },

  {
    headerName: "Regulierungs Beleg",
    field: "regulierungsBeleg",
    minWidth: 110,
  },

  {
    headerName: "Anlagen",
    field: "anlagen",
    minWidth: 80,
  },

  {
    headerName: "Region",
    field: "region",
    minWidth: 90,
  },

  {
    headerName: "Notiz vorhanden seit",
    field: "notizVorhandenSeit",
    minWidth: 140,
    maxWidth: 140,
    // valueFormatter: (params) =>
    //   DateUtils.optionalTimeArrayToGermanString(params.value),
  },

  {
    headerName: "Notiz",
    field: "latestNote",
    minWidth: 260,
  },

  {
    headerName: "Status",
    field: "statusNode",

    cellRenderer: CellValueRenderer,
    maxWidth: 160,
    minWidth: 160,
    cellClass: "ag-cell-center-justified-content",
  },
];

export default UnplausibleRechnungenColumnDefs;

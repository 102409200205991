import { EpcomResponse } from "App/service/http.service";
import CockpitInformationDTO from "../interface/CockpitInformation";
import CockpitVisibilitiesDTO from "../interface/CockpitVisibilities";
import EpgCardInformationDTO from "../interface/EpgCardInformation";

export const AxiosResponseToCockpitVisibilitiesDTOMapper = {
  map: (response: EpcomResponse) => {
    let data = response.data;

    let newDTO: CockpitVisibilitiesDTO = {
      anwendernachrichtShow: data.anwendernachrichtShow,

      atMmFailedShow: data.atMmFailedShow,

      epgKachelShow: data.epgKachelShow,

      erreichbarkeitWarningsShow: data.erreichbarkeitWarningsShow,

      kaufanfragenShow: data.kaufanfragenShow,

      notizenWiedervorlageShow: data.notizenWiedervorlageShow,

      rnbtbDsmdFailedShow: data.rnbtbDsmdFailedShow,

      sapRdNichtZugeordnetGhShow: data.sapRdNichtZugeordnetGhShow,

      zurBearbeitungAnstehendClearingShow:
        data.zurBearbeitungAnstehendClearingShow,

      zurBearbeitungAnstehendLogfileShow:
        data.zurBearbeitungAnstehendLogfileShow,

      zurBearbeitungAnstehendStammdatenShow:
        data.zurBearbeitungAnstehendStammdatenShow,
    };
    return newDTO;
  },
};

export const AxiosResponseToCockpitInformationDTOMapper = {
  map: (response: EpcomResponse) => {
    let data = response.data;

    let newDTO: CockpitInformationDTO = {
      anwendernachricht: data.anwendernachricht
        ? data.anwendernachricht.nachricht
        : "",

      atMmFailed: data.atMmFailed,

      erreichbarkeitWarnings: data.erreichbarkeitWarnings,

      kaufanfragen: data.kaufanfragen,

      notizenWiedervorlage: data.notizenWiedervorlage,

      rnbtbDsmdFailed: data.rnbtbDsmdFailed,

      sapRdNichtZugeordnetGh: data.sapRdNichtZugeordnetGh,

      zurBearbeitungAnstehendStammdaten: data.zurBearbeitungAnstehendStammdaten,

      zurBearbeitungAnstehendClearing: data.zurBearbeitungAnstehendClearing,

      zurBearbeitungAnstehendLogfile: data.zurBearbeitungAnstehendLogfile,

      sapUnplausibleRechnungen: data.sapUnplausibleRechnungen,
    };
    return newDTO;
  },
};

export const AxiosResponseToEpgCardInformationDTO = {
  map: (response: EpcomResponse) => {
    let data = response.data;
    let newDTO: EpgCardInformationDTO = {
      alsLoginDritterAnzahl: data.epgKachelDTO.alsLoginDritterAnzahl,
      alsLoginDritterExists: data.epgKachelDTO.alsLoginDritterExists,

      archivAuslastungExceeded: data.epgKachelDTO.archivAuslastungExceeded,

      archivTooManyFiles: data.epgKachelDTO.archivTooManyFiles,
      archivTooManyFilesThreshold:
        data.epgKachelDTO.archivTooManyFilesThreshold,

      erreichbarkeitIpBereicheFailed:
        data.epgKachelDTO.erreichbarkeitIpBereicheFailed,
      erreichbarkeitIpBereicheFailedAnzahl:
        data.epgKachelDTO.erreichbarkeitIpBereicheFailedAnzahl,

      pingInvalidAutomatenAnzahl: data.epgKachelDTO.pingInvalidAutomatenAnzahl,

      sapRdPossesWithoutEpcomRegions:
        data.epgKachelDTO.sapRdPossesWithoutEpcomRegions,

      sapRdCopyAnlagenFailed: data.epgKachelDTO.sapRdCopyAnlagenFailed,

      sapRdCopyExcelFailed: data.epgKachelDTO.sapRdCopyExcelFailed,

      sapRdPossesWithoutEpcomRegionsAnzahl:
        data.epgKachelDTO.sapRdPossesWithoutEpcomRegionsAnzahl,

      jobsOk: data.epgKachelDTO.jobsOk,

      jobCleanupAutomatLogfilesFailed:
        data.epgKachelDTO.jobCleanupAutomatLogfilesFailed,

      jobClearerStammdatenAbrufFailed:
        data.epgKachelDTO.jobClearerStammdatenAbrufFailed,

      jobClearerVerarbeiteteDateienFailed:
        data.epgKachelDTO.jobClearerVerarbeiteteDateienFailed,

      jobClearingFehlerFailed: data.epgKachelDTO.jobClearingFehlerFailed,

      jobDatenbankAuftraegeCleanupFailed:
        data.epgKachelDTO.jobDatenbankAuftraegeCleanupFailed,

      jobDetermineLogfileFehlerFailed:
        data.epgKachelDTO.jobDetermineLogfileFehlerFailed,

      jobDpgClearerVerfuegbarkeitFailed:
        data.epgKachelDTO.jobDpgClearerVerfuegbarkeitFailed,

      jobDpgStammdatenFailed: data.epgKachelDTO.jobDpgStammdatenFailed,

      jobEpgArchivierungFailed: data.epgKachelDTO.jobEpgArchivierungFailed,

      jobErreichbarkeitsReportFailed:
        data.epgKachelDTO.jobErreichbarkeitsReportFailed,

      jobGhChangeFailed: data.epgKachelDTO.jobGhChangeFailed,

      jobLoginDritterFailed: data.epgKachelDTO.jobLoginDritterFailed,

      jobRnBtbMigrateFailed: data.epgKachelDTO.jobRnBtbMigrateFailed,

      jobRnBtbSerialsNachrichtencodeAccessFailed:
        data.epgKachelDTO.jobRnBtbSerialsNachrichtencodeAccessFailed,

      jobSapRechnungsdatenCopyFilesFailed:
        data.epgKachelDTO.jobSapRechnungsdatenCopyFilesFailed,

      jobSapRechnungsdatenPosImportFailed:
        data.epgKachelDTO.jobSapRechnungsdatenPosImportFailed,

      jobSapStammdatenMigrationFailed:
        data.epgKachelDTO.jobSapStammdatenMigrationFailed,

      sapUnplausibleRechnungen: data.sapUnplausibleRechnungen,
    };
    return newDTO;
  },
};

import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { PropsWithChildren, useState } from "react";

import { EditNote } from "@mui/icons-material";
import "./fieldelementinfo.scss";

export type FieldElementInfoBundlePopperContentFunction = (
  dismissAction: Function
) => React.ReactNode;

interface FieldElementInfoBundleProps {
  label?: string;
  popperContent: FieldElementInfoBundlePopperContentFunction;
  TypographyProps?: TypographyProps;
  popperClassName?: string;
  disabled?: boolean;
}

const FieldElementInfoBundle = <T,>(
  props: PropsWithChildren<FieldElementInfoBundleProps>
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Stack className="epcom__FieldElementInfoBundle" columnGap={2}>
        {props.label && (
          <Typography
            id={`fieldelementinfobundle-label-${props.label}`}
            {...props.TypographyProps}
          >
            {props.label}
          </Typography>
        )}

        <Stack
          className="epcom__FieldElementInfoBundleContent"
          direction="row"
          columnGap={1}
        >
          <Stack rowGap={0.2}>{props.children}</Stack>
          <Stack>
            <IconButton
              size="small"
              onClick={handleClick}
              edge="end"
              disabled={props.disabled}
            >
              <EditNote />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        disablePortal={false}
        className={`epcom__Popover__popper ${props.popperClassName ?? ""}`}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>{props.popperContent(handleClickAway)}</Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default FieldElementInfoBundle;

import AutomatPanelRoutes from "page/Automatensuche/components/Automatenblatt/components/AutomatDetailPanelContainer/routes";
import { generateDefaultAutomatenblattInformation } from "page/Automatensuche/components/Automatenblatt/generator/defaultAutomatenblattInformation";
import { useCallback, useEffect, useState } from "react";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import configureArticleSearchStore from "./automat/automat-artikelsuche-store";
import configureAutomatCheckStore from "./automat/automat-check-store";
import configureClearingFehlerStore from "./automat/automat-clearingfehler-store";
import configureAutomatDpgStammdatenStore from "./automat/automat-dpg-stammdaten-store";
import configureDsmdStatusStore, {
  FETCH_AUTOMAT_DSMD_STATUS,
} from "./automat/automat-dsmd-status-store";
import configureAutomatHistoryStore from "./automat/automat-history-store";
import configureAutomatLogfileStore from "./automat/automat-logfile-store";
import configureLogfilefehlerStore from "./automat/automat-logfilefehler-store";
import configureAutomatStatusStore, {
  FETCH_AUTOMAT_STATUS,
} from "./automat/automat-status-store";
import configureAutomatenblatInformationStore, {
  FETCH_AUTOMATTENBLATT_INFORMATION,
} from "./automat/automatenblatt-information-store";
import configureChangeTrackStore from "./automat/change-tracker-store";
import configureSapRdVertragStore from "./automat/sap-rd-vertragsdaten-store";
import configureUnplausibleRechnungItemsStore from "./automat/unplausible-rechnung-items-store";
import configureViewInfoStore from "./automat/view-info-store";
import AutomatAction, { AutomatActionPayload } from "./interface/AutomatAction";
import AutomatDataListener from "./interface/AutomatDataListener";
import AutomatDataRecord from "./interface/AutomatDataRecord";

export type AutomatDataStore = Array<AutomatDataRecord>;
export type DataFetchMonitor = React.Dispatch<React.SetStateAction<boolean>>;
export type DispatchAutomatDataFunction = (
  actionIdentifier: string,
  payload?: AutomatActionPayload,
  callbackOnFail?: Function,
  dataFetchMonitor?: DataFetchMonitor
) => void;

let globalAutomatDatastore: AutomatDataStore = [];
let listeners: Array<AutomatDataListener> = [];
let actions: Array<AutomatAction> = [];

export const TABS_PSEUDO_AUTOMAT_KEY = "key-for-automat-tabs";

const updateAutomatData = (
  automatKey: string,
  data: AutomatDataRecord,
  hasListeners: boolean
) => {
  const index = globalAutomatDatastore.findIndex(
    (e) => e.automatKey === automatKey
  );
  const updatedDatastore = [...globalAutomatDatastore];
  updatedDatastore[index] = {
    ...globalAutomatDatastore[index],
    ...data,
  };
  globalAutomatDatastore = updatedDatastore;
  if (hasListeners === true) {
    const dataListeners = listeners.filter(
      (li) =>
        li.automatKey === automatKey ||
        (data.changes !== undefined && // notify tabs hook to update its change badges
          li.automatKey === TABS_PSEUDO_AUTOMAT_KEY)
    );
    for (const dataListener of dataListeners) {
      dataListener.listener(globalAutomatDatastore);
    }
  }
};

const recordMergedWithChanges = (
  record: AutomatDataRecord
): AutomatenblattInformation => {
  const changes = record.changes ?? [];
  if (changes.length === 0) {
    return record.automatenblattInformation;
  }
  let changedRecord = {
    ...record.automatenblattInformation,
  } as AutomatenblattInformation;

  for (const c of changes) {
    changedRecord = c.merger(
      changedRecord,
      c,
      false
    ) as AutomatenblattInformation;
  }
  return changedRecord;
};

export const useAutomatData = (
  automatKey: string,
  shouldListen = true
): [
  AutomatDataRecord,
  DispatchAutomatDataFunction,
  AutomatenblattInformation,
  AutomatDataStore
] => {
  const setState = useState(globalAutomatDatastore)[1];

  const dispatch: DispatchAutomatDataFunction = useCallback(
    (
      actionIdentifier: string,
      payload?: AutomatActionPayload,
      callbackOnFail?: Function,
      dataFetchMonitor?: DataFetchMonitor
    ) => {
      const actionIndex = actions.findIndex(
        (e) => e.identifier === actionIdentifier
      );
      if (actionIndex >= 0) {
        const index = globalAutomatDatastore.findIndex(
          (e) => e.automatKey === automatKey
        );
        if (dataFetchMonitor) dataFetchMonitor(true);
        actions[actionIndex].action(
          globalAutomatDatastore[index],
          {
            ...payload,
            automatKey: automatKey,
          } as AutomatActionPayload,
          (data) => {
            if (dataFetchMonitor) dataFetchMonitor(false);
            updateAutomatData(
              automatKey,
              payload?.clearChanges ? { ...data, changes: [] } : data,
              shouldListen
            );
          },
          (error: Error) => {
            if (dataFetchMonitor) dataFetchMonitor(false);
            if (callbackOnFail) {
              callbackOnFail(error);
            }
          }
        );
      }
    }, // eslint-disable-next-line
    [automatKey]
  );

  AutomatStoreController.initialiseAutomat(automatKey);
  var recordIndex = globalAutomatDatastore.findIndex(
    (e) => e.automatKey === automatKey
  );
  if (globalAutomatDatastore[recordIndex].needsFetch) {
    globalAutomatDatastore[recordIndex].needsFetch = false;
    // fetch initial data
    dispatch(FETCH_AUTOMATTENBLATT_INFORMATION);
    dispatch(FETCH_AUTOMAT_STATUS);
    dispatch(FETCH_AUTOMAT_DSMD_STATUS);
  }

  useEffect(() => {
    if (shouldListen) {
      listeners.push({
        automatKey: automatKey,
        listener: setState,
      });
    }

    return () => {
      if (shouldListen) {
        listeners = listeners.filter((li) => li.listener !== setState);
      }
    };
  }, [automatKey, setState, shouldListen]);

  return [
    globalAutomatDatastore[recordIndex],
    dispatch,
    recordMergedWithChanges(globalAutomatDatastore[recordIndex]),
    globalAutomatDatastore,
  ];
};

export const AutomatStoreController = {
  removeAutomat: (automatKey: string) => {
    globalAutomatDatastore = globalAutomatDatastore.filter(
      (li) => automatKey !== li.automatKey + ""
    );
  },
  automatRoutePath: (serienNummer: string): string => {
    var recordIndex = globalAutomatDatastore.findIndex(
      (e) => serienNummer === e.automatenblattInformation.seriennummer + ""
    );
    if (recordIndex >= 0) {
      return "/" + globalAutomatDatastore[recordIndex].viewInfo.infoPanel;
    }
    return "/rns";
  },
  initialiseAutomat: (
    automatKey: string,
    defaultPanelIndex?: number,
    serialNumber?: number
  ) => {
    var recordIndex = globalAutomatDatastore.findIndex(
      (e) => e.automatKey === automatKey
    );
    if (recordIndex === -1) {
      const panelIndex = defaultPanelIndex ?? 0;
      recordIndex = globalAutomatDatastore.length;
      globalAutomatDatastore.push({
        needsFetch: automatKey !== TABS_PSEUDO_AUTOMAT_KEY,
        automatKey: automatKey,
        viewInfo: {
          infoPanel: Object.values(AutomatPanelRoutes)[panelIndex],
          infoPanelIndex: panelIndex,
          statusRecordCount: 10,
        },
        automatenblattInformation:
          generateDefaultAutomatenblattInformation(serialNumber),
      });
      const tabsPseudoAutomatRecord = globalAutomatDatastore.find(
        (e) => e.automatKey === TABS_PSEUDO_AUTOMAT_KEY
      );
      if (tabsPseudoAutomatRecord)
        updateAutomatData(
          TABS_PSEUDO_AUTOMAT_KEY,
          { ...tabsPseudoAutomatRecord },
          true
        );
    }
  },
  registerAutomatDataStoreActions: (userActions: Array<AutomatAction>) => {
    actions = [...actions, ...userActions];
  },
};

export const configureAutomatDataStore = () => {
  configureAutomatenblatInformationStore();
  configureAutomatStatusStore();
  configureDsmdStatusStore();
  configureViewInfoStore();
  configureAutomatCheckStore();
  configureArticleSearchStore();
  configureClearingFehlerStore();
  configureAutomatDpgStammdatenStore();
  configureAutomatHistoryStore();
  configureLogfilefehlerStore();
  configureAutomatLogfileStore();
  configureSapRdVertragStore();
  configureChangeTrackStore();
  configureUnplausibleRechnungItemsStore();
};

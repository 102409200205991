import { Button, Grid, Stack, Typography } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import useSnackbarGenerator from "App/hook/use-snackbars";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UICheckbox from "global/components/UI/UICheckbox/UICheckbox";
import {
  AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID,
  AUTOMATENBLATT_NOTIZTYP_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useTransponder } from "global/hook/transponder/use-transponder";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import DateUtils, { getTomorrow } from "global/util/DateUtils";
import { useCallback, useContext, useEffect, useState } from "react";
import NoteService from "service/data-service/notizen-controller/Note.service";
import NoteRequestDTO from "service/data-service/notizen-controller/interface/NoteRequest";
import { Note } from "service/data-service/notizen-controller/interface/NoteSearchResult";
import { generateDefaultNoteRequest } from "service/data-service/notizen-controller/mapping/generator/defaultNoteDTOGenerator";
import { AutomatUnplausibleRechnungItem } from "service/sap-service/interface/UnplausibleRechnungRecord";
import "../panels.scss";
import NoteDialog from "./components/NoteDialog";
import UnplausibleRechnungNoteInfo from "./components/UnplausibleRechnungNoteInfo";
import { generateDefaultNote } from "./generator/defaultNote";
import "./notes.scss";
import NotizenController from "./script/NotizenController";
import { NotizenPanelColumnDefs } from "./script/columnDefinition";

interface NotizenPanelProps {
  automatKey: string;
  disabled?: boolean;
}

export const UNPLAUSIBLE_RECHNUNG_NOTE_TYPE = "UNPLAUSIBLE_RECHNUNGEN";

const NotizenPanel = (props: NotizenPanelProps) => {
  const snackbarGenerator = useSnackbarGenerator();
  const notizTypEnumerator = useEnumerator(AUTOMATENBLATT_NOTIZTYP_ENUM_ID);
  const notizStatusEnumerator = useEnumerator(
    AUTOMATENBLATT_NOTIZSTATUS_ENUM_ID
  );

  const [noteRequest, setNoteRequest] = useState<NoteRequestDTO>({
    ...generateDefaultNoteRequest(),
    automatKey: props.automatKey,
  });
  const [readableAth, setReadableAth] = useState<boolean>(false);
  const [notesToGrid, setNotesToGrid] = useState<Array<Note>>();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false);
  const [noteToDialog, setNoteToDialog] = useState<Note>(generateDefaultNote());
  const [resetFieldsOnToggle, setResetFieldsOnToggle] = useState(false);
  const accessContext = useContext(AccessContext);

  const [selectedNotizType, setSelectedNotizType] = useState<string>("");
  const [noteRechnungItem, setNoteRechnungItem] =
    useState<AutomatUnplausibleRechnungItem | null>(null);

  const windowViewport = useWindowViewport(0, 415);

  const [automatInfo, transponderCtrl] = useTransponder();

  function clearTextFields() {
    setReadableAth(false);
    setNoteRequest(generateDefaultNoteRequest());
    setNoteRechnungItem(null);
    setResetFieldsOnToggle((state) => !state);
  }

  function handleNewNoteClick(noteRequest: NoteRequestDTO) {
    noteRequest.notizKopfText = noteRequest.notizKopfText.trim();
    noteRequest.vorgangNr = noteRechnungItem?.vorgang_nr ?? "";
    noteRequest.regulierungsBeleg = noteRechnungItem?.regulierungs_beleg ?? "";
    setNoteRequest(noteRequest);

    if (NotizenController.isValidNewNoteInput(noteRequest, snackbarGenerator)) {
      NoteService.noteSaveOrUpdate(noteRequest, (data: any) => {
        setNotesToGrid(
          (existingNotes) => [data, ...(existingNotes ?? [])] as Array<Note>
        );
        clearTextFields();
      });
    }
  }

  const showNote = (note: Note) => {
    setShowNoteDialog(true);
    setNoteToDialog(note);
  };

  const retrieveNotes = useCallback(() => {
    setIsFetchingData(true);
    NoteService.noteSearch(
      "automatKey",
      props.automatKey,
      (searchResult) => {
        setIsFetchingData(false);
        setNotesToGrid(searchResult.rows);
      },
      () => {
        setIsFetchingData(false);
      }
    );
  }, [props.automatKey]);

  // Load all Notes on page render and new note push
  useEffect(() => {
    retrieveNotes();
  }, [retrieveNotes]);

  useEffect(() => {
    if (notesToGrid && automatInfo?.noteKey) {
      const note = notesToGrid.find(
        (note) => note.notizKey === automatInfo.noteKey
      );
      if (note) showNote(note);

      transponderCtrl.clearData();
    }
  }, [notesToGrid]);

  return (
    <Stack spacing={2}>
      <Grid
        container
        className="gridForNotes"
        rowSpacing={1}
        columnSpacing={2}
        width={"auto"}
      >
        <Grid
          className="gridItemForNotes"
          item
          md={12}
          lg={6}
          order={{ xs: 1 }}
        >
          <SelectBox
            className="noteInputElement"
            label="Notiz Typ:"
            enumerator={notizTypEnumerator}
            id="notiz-typ"
            getSelectedValue={(item) => {
              setSelectedNotizType(item?.id.toString() ?? "");
              setNoteRequest({
                ...noteRequest,
                type: item?.id.toString() ?? "",
              });
            }}
            reset={resetFieldsOnToggle}
            AutocompleteProps={{ className: "inputTextFieldForNotes" }}
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
          />
        </Grid>
        <Grid
          item
          className="gridItemForNotes"
          md={12}
          lg={6}
          order={{ xs: 2, md: 2, lg: 3 }}
        >
          <SelectBox
            className="noteInputElement"
            label="Notiz Status:"
            enumerator={notizStatusEnumerator}
            id="notiz-status"
            getSelectedValue={(item) => {
              setNoteRequest({
                ...noteRequest,
                status: item?.id.toString() ?? "",
              });
            }}
            reset={resetFieldsOnToggle}
            AutocompleteProps={{ className: "inputTextFieldForNotes" }}
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
          />
        </Grid>
        <Grid
          item
          className="gridItemForNotes"
          md={12}
          lg={6}
          order={{ xs: 3, md: 3, lg: 2 }}
        >
          <DatePickerElement
            className="datePickerForNotes noteInputElement"
            label="Wiedervorlage:"
            getSelectedValue={(value) => {
              setNoteRequest({
                ...noteRequest,
                wiedervorlage:
                  DateUtils.formatDateToAPIDateString(value ?? undefined) ?? "",
              });
            }}
            resetToDefault={resetFieldsOnToggle}
            id="wiedervorlage"
            TypographyProps={{ className: "labelForNotes fontForNotes" }}
            TextFieldProps={{ className: "inputTextFieldForNotes" }}
            datePickerProperties={{
              minDate: getTomorrow(),
              disablePast: true,
            }}
          />
        </Grid>
        <Grid
          item
          className="gridItemForNotes"
          xs={12}
          md={12}
          lg={6}
          order={{ xs: 4 }}
        >
          <UICheckbox
            disabled={
              !accessContext.getAccessContainer()
                .showAutomatenTabCbNotizAthLeseberechtigung
            }
            label="Leseberechtigung:"
            value={readableAth}
            id="leseberechtigung"
            onChange={(event) => {
              setReadableAth(event.target.checked);
              setNoteRequest({
                ...noteRequest,
                notizReadRoles: event.target.checked ? "ATH" : null,
              });
            }}
            TypographyProps={{ className: "fontForNotes" }}
          >
            <Typography className="fontForNotes">ATH</Typography>
          </UICheckbox>
        </Grid>

        {selectedNotizType === UNPLAUSIBLE_RECHNUNG_NOTE_TYPE && (
          <Grid
            item
            className="gridItemForNotes"
            md={12}
            lg={12}
            order={{ xs: 5 }}
          >
            <UnplausibleRechnungNoteInfo
              automatkKey={props.automatKey}
              onNoteInfoItemSelected={(item) => {
                console.log("item", item);
                setNoteRechnungItem(item);
              }}
              TypographyProps={{ className: "labelForNotes fontForNotes" }}
            />
          </Grid>
        )}
        <Grid item md={12} lg={12} order={{ xs: 6 }}>
          <Typography className="labelForNotes fontForNotes">
            Notiz Text:
          </Typography>
        </Grid>
        <Grid item md={12} lg={12} order={{ xs: 7 }}>
          <TextValue
            value={noteRequest.notizKopfText}
            id={"notiz-text"}
            onChange={(event) => {
              setNoteRequest({
                ...noteRequest,
                notizKopfText: event.target.value,
              });
            }}
            TextFieldProps={{
              className: "fontForNotes noteTextInput",
              multiline: true,
            }}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        className="createNewNoteButton"
        disabled={
          !accessContext.getAccessContainer().showAutomatenTabBtnNotizAnlegen ||
          props.disabled
        }
        onClick={() => {
          handleNewNoteClick({
            ...noteRequest,
            notizAktion: "SAVENEWNOTIZ",
          });
        }}
      >
        Neue Notiz anlegen
      </Button>

      <DataGrid
        height={windowViewport.height}
        columnDefs={NotizenPanelColumnDefs}
        rowsDataDef={{
          data: notesToGrid,
          isFetchingData: isFetchingData,
        }}
        onRowClicked={(row) => showNote(row)}
      />
      <NoteDialog
        note={noteToDialog}
        open={showNoteDialog}
        refreshNotes={retrieveNotes}
        onClose={() => setShowNoteDialog(false)}
      />
    </Stack>
  );
};

export default NotizenPanel;

import "@ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "@ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { Box, Chip, CircularProgress, Fab, Stack } from "@mui/material";
import { CellClickedEvent, GridApi, GridOptions } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import { Cached, HistoryToggleOff } from "@mui/icons-material";
import useDataCache from "global/hook/cache/use-data-cache";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import "./dataGrid.scss";
import DataGridProps from "./interface/DataGridProps";

const DEFAULT_GRID_HEIGHT = 300;
const DEFAULT_GRID_DATA_CACHE_MAX_AGE = 300;

const DataGrid = <T,>(props: PropsWithChildren<DataGridProps<T>>) => {
  // add cache props
  const cacheProps = {
    cacheKey: props.rowsDataDef.cacheProps?.cacheKey ?? "",
    expireAgeInSeconds:
      props.rowsDataDef.cacheProps?.expireAgeInSeconds ??
      DEFAULT_GRID_DATA_CACHE_MAX_AGE,
    lifespan: props.rowsDataDef.cacheProps?.lifespan ?? "SHORT",
  };
  const [cacheData, dataCacheFill] = useDataCache<T>(cacheProps);
  const [cacheAgeProgress, setCacheAgeProgress] = useState<number>(0);

  const hasCachedData = (cacheData.data ?? []).length > 0;
  const showCacheDataActions = cacheData.status && cacheData.status !== "EMPTY";

  const [rowData, setRowData] = useState<Array<T>>(
    hasCachedData ? cacheData.data : []
  ); // Set rowData to Array of Objects, one Object per Row
  const [gridApi, setGridApi] = useState<GridApi>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [canTriggerCacheRefresh, setCanTriggerCacheRefresh] = useState<boolean>(
    cacheData.sourceDatalifespan === "SHORT" || cacheData.status === "EXPIRED"
  );

  const onRowClicked = props.onRowClicked;
  const cellClickedListener = useCallback(
    (event: CellClickedEvent) => {
      if (onRowClicked) onRowClicked(event.data, event.column.getColId());
    },
    [onRowClicked]
  );
  const hasActions = props.exportDef || props.gridActions;
  const hasUncontrolledData =
    props.rowsDataDef.uncontrolledDataFetchCall !== undefined;

  const gridOptions: GridOptions = {
    ...props.gridOptions,
    defaultColDef: {
      // Default Column Properties
      width: 120,
      sortable: true,
      resizable: true,
      flex: 1,
      ...props.gridOptions?.defaultColDef,
    },
    overlayNoRowsTemplate: "keine Daten vorhanden",
    columnDefs: props.columnDefs, // Column Defs for Columns
    rowHeight: 26,
    headerHeight: 36,
    animateRows: true, // Optional - set to 'true' to have rows animate when sorted
    overlayLoadingTemplate: "Wird geladen...",
  };

  const fetchData = (force = false) => {
    if (
      hasUncontrolledData &&
      (force ||
        !hasCachedData ||
        (cacheData.status === "EXPIRED" &&
          cacheData.sourceDatalifespan === "SHORT"))
    ) {
      setRefreshing(true);
      props.rowsDataDef.uncontrolledDataFetchCall!({
        callbackOnSuccess: (data: T[]) => {
          setRefreshing(false);
          setRowData(data);
          dataCacheFill(data);
        },
        callbackOnFail: (error: Error) => {
          setRefreshing(false);
          // ignore the error param since the errors are handled higher up in the rest call interceptor
          setRowData([]); // this will hide the loading overlay and show the no rows overlay
        },
      });
    }
  };

  useEffect(() => {
    if (hasUncontrolledData) {
      return;
    }
    if (props.rowsDataDef.isFetchingData === true) {
      gridApi?.showLoadingOverlay();
    } else {
      if (cacheData) dataCacheFill(props.rowsDataDef.data ?? []);
      if ((props.rowsDataDef.data ?? []).length === 0) {
        gridApi?.showNoRowsOverlay();
      } else {
        gridApi?.hideOverlay();
      }
    } // eslint-disable-next-line
  }, [props.rowsDataDef]);

  useEffect(() => {
    setCacheAgeProgress(
      cacheData.lifespanInSeconds === 0
        ? 0
        : (100 *
            (cacheData.lifespanInSeconds - cacheData.secondsLeftToExpire)) /
            cacheData.lifespanInSeconds
    );
    setCanTriggerCacheRefresh(
      cacheData.sourceDatalifespan === "SHORT" || cacheData.status === "EXPIRED"
    );
  }, [cacheData]);

  const dataGridContainerClassName = `ag-theme-alpine relativeGrid ${
    hasActions || showCacheDataActions ? "withGridActionsRow" : ""
  }`;

  const gridData = props.rowsDataDef.uncontrolledDataFetchCall
    ? rowData
    : props.rowsDataDef.data;

  return (
    <div
      className={dataGridContainerClassName}
      style={{ height: props.height ?? DEFAULT_GRID_HEIGHT }}
    >
      {(hasActions || showCacheDataActions) && (
        <Stack
          className="gridActionsRow buttonStack"
          justifyContent="space-between"
        >
          <Stack className="gridActionsRow filterButtons">
            {props.gridActions}
          </Stack>

          {(props.exportDef || showCacheDataActions) && (
            <Stack className="gridActionsRow">
              {props.exportDef && (
                <Chip
                  className="exportChip"
                  size="small"
                  variant="outlined"
                  label={props.exportDef?.name ?? "EXCEL Export"}
                  clickable
                  onClick={() => props.exportDef?.action!()}
                  disabled={props.exportDef.disabled}
                />
              )}
              {showCacheDataActions && (
                <Box className="dataGrid__cacheDataActionContainer">
                  <Fab
                    color="primary"
                    onClick={() => fetchData(true)}
                    size="small"
                    disabled={refreshing || !canTriggerCacheRefresh}
                  >
                    {refreshing || !canTriggerCacheRefresh ? (
                      <HistoryToggleOff />
                    ) : (
                      <Cached />
                    )}
                  </Fab>
                  <CircularProgress
                    color="warning"
                    className="dataGrid___cacheAgeProgress"
                    variant={refreshing ? "indeterminate" : "determinate"}
                    value={cacheAgeProgress}
                  />
                </Box>
              )}
            </Stack>
          )}
        </Stack>
      )}
      <AgGridReact
        ref={props.dataGridRef}
        className={props.className}
        rowHeight={props.rowHeight}
        gridOptions={gridOptions}
        rowData={gridData} // Row Data for Rows
        rowSelection="single"
        onCellClicked={cellClickedListener} // Optional - registering for Grid Event
        onRowDataUpdated={(event) => {
          if (props.onRowSelected) props.onRowSelected(undefined);
          if (props.onRowDataChanged) props.onRowDataChanged(gridData);
        }}
        onRowSelected={(event) => {
          if (props.onRowSelected && event.node.isSelected())
            props.onRowSelected(event.data);
        }}
        onGridReady={(event) => {
          setGridApi(event.api);
          if (props.onGridReady) props.onGridReady(event);
          fetchData();
        }}
      />
    </div>
  );
};

export default DataGrid;
